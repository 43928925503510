@use "src/color";

.contact-page {
  .text-zone {
    position: absolute;
    left: 5%;
    top: 50%;
    width: 35%;
    min-width: 360px;
    max-height: 90%;
    transform: translateY(-50%);
    vertical-align: middle;
    display: table-cell;

    h1 {
      font-family: "Coolvetica", sans-serif;
      font-size: 56px;
      font-weight: 400;
    }

    h2 {
      font-size: 22px;
      font-weight: 600;
      color: color.$white-alt;
      letter-spacing: 1px;
    }
  }
}