@use "src/color";

.nav-bar {
  background: color.$background-navbar;
  width: 100px;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 3;
  min-height: 500px;

  .logo {
    display: block;
    text-align: center;
    padding: 8px 0;
    text-decoration: none;

    .logo-t {
      font-family: "Autumn Flowers", cursive;
      font-size: 40px;
      color: color.$white;
      margin-bottom: 0;
      transform: translate(-5%, 0);
    }
    .logo-sub {
      color: color.$white;
      font-weight: 600;
      letter-spacing: 1px;
      margin-top: 0;
    }
  }

  nav {
    display: block;
    text-align: center;
    position: absolute;
    height: 200px;
    top: 50%;
    margin-top: -120px;
    width: 100%;

    a {
      color: color.$foreground-navbar;
      font-size: 32px;
      line-height: 44px;
      height: 54px;
      display: block;
      position: relative;
      text-decoration: none;

      i {
        transition: all 0.3s ease-out;
      }

      &:hover {
        color: color.$accent;

        svg {
          opacity: 0;
        }

        &:after {
          opacity: 1;
        }
      }

      &:after {
        content: "";
        font-size: 24px;
        font-weight: 600;
        letter-spacing: 1px;
        line-height: 44px;
        position: absolute;
        top: 0;
        bottom: 2px;
        display: block;
        width: 100%;
        text-align: center;
        opacity: 0;
        transition: all 0.3s ease-out;
      }
    }
    a.home-link {
      &:after {
        content: "Home";
      }
    }

    a.about-link {
      &:after {
        content: "About";
      }
    }

    a.contact-link {
      &:after {
        content: "Contact";
      }
    }

    a.active {
      svg {
        color: color.$primary;
      }
    }
  }

  ul {
    position: absolute;
    bottom: 20px;
    width: 100%;
    display: block;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;

    li {
      a {
        color: color.$foreground-navbar;
        padding: 7px 0;
        display: block;
        font-size: 28px;
        line-height: 30px;

        &:hover svg {
          color: color.$primary;
        }
      }
    }
  }
}