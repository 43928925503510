@import "animate.css";

@font-face {
  font-family: "Helvetica Neue";
  src: url("assets/fonts/helvetica-neu.ttf") format("truetype");
}

@font-face {
  font-family: "La Belle Aurore";
  src: url("assets/fonts/LaBelleAurore.woff2") format("woff2");
}

@font-face {
  font-family: "Coolvetica";
  src: url("assets/fonts/CoolveticaRg-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Autumn Flowers";
  src: url("assets/fonts/AutumnFlowers.otf") format("opentype");
}

input,
textarea {
  font-family: "Helvetica Neue", sans-serif;
}
