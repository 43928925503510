@use "src/color";

.page {
  width: 100%;
  height: 100%;
  position: absolute;
}

.container {
  width: 100%;
  height: 90%;
  min-height: 575px;
  will-change: contents;
  position: absolute;
  opacity: 0;
  top: 5%;
  left: 100px;
  margin: 0 auto;
  z-index: 1;

  transform-style: preserve-3d;
  animation: fadeIn 0.5s forwards;
  animation-delay: 0.25s;
}

.tags {
  color: color.$primary;
  opacity: 0.625;
  font-family: "La Belle Aurore", sans-serif;
  font-size: 18px;
  position: absolute;
  bottom: 0;
  left: 140px;
}

.tags-h1 {
  &::before {
    font-family: "La Belle Aurore", sans-serif;
    font-size: 18px;
    color: color.$primary;
    opacity: 0.625;

    position: absolute;
    margin-top: -10px;
    left: -10px;
    content: "<h1>";
  }

  &::after {
    font-family: "La Belle Aurore", sans-serif;
    font-size: 18px;
    color: color.$primary;
    opacity: 0.625;

    position: absolute;
    margin-top: 42px;
    margin-left: 20px;
    content: "</h1>"
  }
}

.top-tags {
  bottom: auto;
  top: 35px;
}

.bottom-tags {

}

.bottom-tag-html {
  margin-left: -20px;
}
