$primary: #86a5d9;
$secondary: #5f4bb6;
$accent: #26f0f1;
$background: #202a25;
$foreground: #c4ebc8;

$background-navbar: #202020;
$foreground-navbar: #505050;

$white: #e0e8d8;
$white-alt: #a0a0a0;
