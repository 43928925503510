html {
    font-size: 62.5%;
}

body {
    margin: 0;
    font: 300 11px/1.4 "Helvetica Neue", "sans-serif";
    color: #c4ebc8;
    background: #202a25;

    overflow: hidden;
    display: block;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
